import React, { useRef } from "react"
import styled from "styled-components"

import photo from "../../../static/itnick.jpg"
import ITguy from "../../assets/videos/ITGuyShort.mp4"

const VideoComp = () => {
  //const [show, setShow] = useState(true)

  const vidRef = useRef(null)

  const handlePlayVideo = () => {
    // vidRef.current.muted = !vidRef.current.muted
    vidRef.current.play()
    // vidRef.current.onplaying = function () {
    //   console.log("Video is now loaded and playing")
    // }
  }

  const pauseVideo = () => {
    vidRef.current.pause()
  }

  return (
    <>
      <Container>
        <Title>What's this guy's name again?</Title>
        <Wrap
          onClick={handlePlayVideo}
          //onMouseEnter={playVideo}
          onMouseLeave={pauseVideo}
        >
          <div className="playpause" />
          <img src={photo} alt="" />
          <video
            id="video"
            ref={vidRef}
            //autoPlay={true}
            // loop={true}
            playsInline={true}
            //muted
          >
            <track default kind="captions" />
            <source src={ITguy} type="video/mp4" />
          </video>
        </Wrap>
      </Container>
    </>
  )
}

const Container = styled.div`
  /* display: flex; */
  /* justify-content: center; */
  margin-top: 30px;
  padding: 30px 0px 26px;
  margin: 0 auto;
  width: 800px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`

const Wrap = styled.div`
  padding-top: 56.25%;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  margin: 100px;
  max-height: 100px;
  img {
    inset: 0px;
    display: block;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    transition: opacity 500ms ease-in-out 0s;
    width: 100%;
    z-index: 1;
    top: 0;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    // setting opacity to 0 gives you an on-hover video effect
    opacity: 1;
    z-index: 0;
  }
  &:hover {
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    video {
      opacity: 1;
      z-index: 4;
    }
    img {
      opacity: 0;
    }
  }
  .playpause {
    // background-image: url(http:\/\/png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
    background-repeat: no-repeat;
    width: 20%;
    height: 20%;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background-size: contain;
    background-position: center;
    z-index: 3;
  }

  @media screen and (max-width: 768px) {
    max-height: 200px;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const Title = styled.h2`
  color: black;
  text-align: center;
  font-size: 3rem;
  font-family: var(--main-font);
  margin-top: 30vh;
  @media screen and (max-width: 768px) {
    margin-top: 20vh;
    padding: 10px;
  }
`

export default VideoComp
