import React from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"

// TODO : Circle Loader, when page is done loading: based on this tut. https://www.youtube.com/watch?v=bhpdMlCnwaM

const Loader = () => {
  const textanim = {
    visible: {
      opacity: 1,
      y: "0px",
    },
    notvisible: {
      opacity: 0,
      y: "100px",
    },
  }
  return (
    <>
      <AnimatePresence>
        <Content>
          <ClipBox>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.1 }}
            >
              N
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              I
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              C
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              K
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              U
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              N
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.7 }}
            >
              G
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              E
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 0.9 }}
            >
              R
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 1 }}
            >
              E
            </ClipText>
            <ClipText
              variants={textanim}
              initial="notvisible"
              animate="visible"
              transition={{ duration: 0.8, delay: 1.1 }}
            >
              R
            </ClipText>
          </ClipBox>
        </Content>
      </AnimatePresence>
    </>
  )
}

const Content = styled.div`
  display: flex;
  min-height: 70vh;
  // background: #252525;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2em;
  width: 100%;
  @media only screen and (max-width: 768px) {
    min-height: 60vh;
  }
`

const ClipBox = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  text-align: center;
  width: 80%;
  height: 120px;
  overflow: hidden;
  color: black;
  // border: 1px solid purple;
`

const ClipText = styled(motion.div)`
  margin: 0 auto;
  font-size: 3rem;
  font-weight: 700;
  font-style: normal;
  @media only screen and (max-width: 768px) {
    font-size: 2.4rem;
  }
`

export default Loader
