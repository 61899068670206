import React from "react"
import styled from "styled-components"

const Title = props => {
  return (
    <Container>
      <Content>{props.title}</Content>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: auto;
  //background: #d6d6d6;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 3%;
  flex-wrap: wrap;
  text-align: center;
  font-size: 5rem;
  color: #393a3d;
  width: 80%;
  line-height: 5.7rem;
  margin: 10% 0px 2% 0px;
  h2 {
    font-size: 5rem;
    color: #252525;
    @media only screen and (max-width: 768px) {
      font-size: clamp(1rem, -0.875rem + 4.333vw, 1.2rem);
      line-height: 1.3rem;
      font-weight: 700;
    }
  }
  .title {
    color: white;
    font-size: 5rem;
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (max-width: 768px) {
      font-size: clamp(1.6rem, -0.875rem + 4.333vw, 2.2rem);
      line-height: 1.3rem;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: clamp(1.6rem, -0.875rem + 4.333vw, 2.2rem);
    line-height: 1.3rem;
    font-weight: 700;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
`

export default Title
