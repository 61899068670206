import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const BigQuote = () => {
  return (
    <Container>
      <Content>
        WHAT DO YOU WANT TO
        <span className="title">COMMUNICATE</span>
        TO YOUR CUSTOMERS?
        <br />
        <br />
        <span className="title">
          I CAN <SLink to="/hire">HELP.</SLink>
        </span>
      </Content>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: auto;
  background: #d6d6d6;
`

const SLink = styled(Link)`
  text-decoration: underline;
  color: #393a3d;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 3%;
  flex-wrap: wrap;
  text-align: center;
  //text-align: center;
  font-size: 5rem;
  color: #393a3d;
  width: 80%;
  line-height: 5.7rem;
  margin: 5% 0px 5% 0px;
  .title {
    // TODO ADD FONT PALATINO LINOTYPE BOLD ITALIC (OR SIMILAR)
    color: white;
    font-size: 5rem;
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (max-width: 768px) {
      font-size: clamp(1.5rem, 4vw, 1.6rem);
      line-height: 1.6rem;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: clamp(1.5rem, 4vw, 1.6rem);
    line-height: 1.6rem;
    font-weight: 700;
    width: 90%;
  }
`

export default BigQuote
