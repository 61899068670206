import React from "react"
import styled from "styled-components"

import seal from "../../../static/seal.png"
import Arrow from "../../assets/arrowdown.svg"

const Seal = () => {
  const scrollDown = () => {
    window.scrollTo({
      top: 600,
      left: 0,
      behavior: "smooth",
    })
  }
  return (
    <>
      <ThreeSixty>
        <Arrow onClick={scrollDown} style={{ color: "red" }} />
        <img className="image" src={seal} alt="Seal of Approval" />
      </ThreeSixty>
    </>
  )
}

const ThreeSixty = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 400px;
  margin: 0 auto;
  svg {
    cursor: pointer;
    color: black !important;
    position: absolute;
    //border: 1px solid red;
    z-index: 2202;
    margin-top: -30px;
  }
  .image {
    cursor: pointer;
    width: 240px;
    height: 240px;
    -webkit-animation: spin 8s linear infinite;
    -moz-animation: spin 8s linear infinite;
    animation: spin 8s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: 0;
  }
`

export default Seal
