import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/Seo.js"
import styled from "styled-components"

import Layout from "./../layout.js"
import VideoComp from "../components/motion/VideoComp.js"
import Loader from "../components/motion/Loader.js"
import Seal from "../components/motion/Seal.js"
import BigQuote from "../components/xdcomponents/BigQuote.js"
import HoverCases from "../components/xdcomponents/HoverCases.js"
import Title from "../components/xdcomponents/Title.js"
import NavButton from "../components/Nav/NavButton.js"
import ToggleComponent from "../components/motion/ToggleComponent.js"
//import TripleFade from "../components/motion/TripleFade.js"

export default function Home() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleLoad = () => {
      console.log("loaded ")
      setLoading(false)
    }
    window.addEventListener("load", handleLoad())

    return () => {
      window.removeEventListener("load", handleLoad)
    }
  }, [])

  return (
    <Layout>
      <Seo title="Front-end Developer & UX Designer" />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.nickungerer.com/" />
      </Helmet>
      {loading && <Load>Loading, one second...</Load>}

      {!loading && (
        <>
          <Loader />
          <Seal />
          <BigQuote />
          <Title title="I can help you with:" />
          <ToggleComponent />
          <Center>
            <NavButton />
          </Center>
          <Title title="I've worked for:" />
          <HoverCases />
          {/*
          note, not responsive (perhaps use clamp for font, or other way of getting it inView + diff quote needed
          <QuoteBox author="Steve Jobs">
            "You've got to find what you love. And that is as true for your work
            as it is for your lovers. Your work is going to fill a large part of
            your life, and the only way to be truly satisfied is to do what you
            believe is great work. And the only way to do great work is to love
            what you do. If you haven't found it yet, keep looking. Don't
            settle. As with all matters of the heart, you'll know when you find
            it. And, like any great relationship, it just gets better and better
            as the years roll on. So keep looking until you find it. Don't
            settle."
          </QuoteBox> */}
          <VideoComp />
        </>
      )}
    </Layout>
  )
}

const Load = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #252525;
`
const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  width: 100%;
`
