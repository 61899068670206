import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
// TODO : Test with Squiggly animation SVG effects
// 1. On Hover, blog squiggly reveal
// 2. website divided into different sections with squiggle.
import { AnchorLink } from "gatsby-plugin-anchor-links"

const JanTwentyTwo = () => {
  //const [offsetX, setOffsetX] = useState(100)
  return (
    <>
      <Content>
        <SLink to="/portfolio">
          <div>Bayer </div>
          <div>Ask Phill</div>

          <div>Building Heroes</div>
        </SLink>
        <SLink to="/portfolio#ipg">
          <div>UNET</div>
          {/* <AnchorLink to="/portfolio#ipg" title="IPG" /> */}

          <div>UM & Cadreon (IPG)</div>
        </SLink>
      </Content>
    </>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    /* display: inline-block; */
    /* border: 5px solid #252525; */
    color: #252525;
    font-size: clamp(2rem, 5.333vw, 5rem);
    /* min-height: max-content; */
    min-height: 20vh;
    background-image: linear-gradient(50deg, #252525, #252525);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    /* background-size: 0% 100%; */
    transition: background-size 0.5s, color 0.5s, font-size 0.5s;
    background-size: 100% 0%;
    cursor: pointer;
    font-weight: 700;
  }
  div:hover {
    background-size: 100% 100%;
    color: #fff;
    /* font-style: italic; */
    font-size: 5.5rem;
    font-size: clamp(2.05rem, 5.433vw, 5.2rem);
  }
  @media only screen and (max-width: 768px) {
    line-height: 3rem;
    font-size: clamp(16px, 4vw, 22px);
  }

  /* @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    overflow: hidden;
    height: auto;
  } */
`
const SLink = styled(Link)`
  text-decoration: none;
`
export default JanTwentyTwo
