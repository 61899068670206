import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const ToggleComponent = () => {
  const [tab, setTab] = useState(0)

  const tabSelect = index => {
    if (index !== tab) {
      setTab(index)
      console.log("tab change")
    }
    console.log(tab)
  }

  const variants = {
    visible: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.6,
      },
    },
    notvisible: {
      opacity: 0,
      y: "10px",
    },
  }

  return (
    <>
      <Cont>
        <Table>
          <TableGrid>
            <TabGrid>
              <Tab onClick={() => tabSelect(0)} tab={tab === 0}>
                Front end development
              </Tab>
              <Tab onClick={() => tabSelect(1)} tab={tab === 1}>
                Brand Identity
              </Tab>
              <Tab onClick={() => tabSelect(2)} tab={tab === 2}>
                UX Design
              </Tab>
              <Tab onClick={() => tabSelect(3)} tab={tab === 3}>
                Online Marketing
              </Tab>
            </TabGrid>
            <DescriptionGrid>
              {tab === 0 && (
                <DesBox
                  variants={variants}
                  initial="notvisible"
                  animate="visible"
                >
                  <p>
                    Are you tired of your current website, do you have an idea
                    for a new app, do you need a new e-commerce solution, or are
                    you just in need of a simple marketing landing page?
                  </p>
                  <p>
                    Let's take a look together, and we can find the solution
                    best tailored to your problem.
                  </p>
                  <p>
                    My preferred stack is React combined with Next.js or Gatsby
                    so you get all the performant elements out of the box to
                    make the solution as fast as possible.
                  </p>
                  <p>
                    However, if another option makes more sense, then we'll use
                    that!
                  </p>
                </DesBox>
              )}
              {tab === 1 && (
                <DesBox
                  variants={variants}
                  initial="notvisible"
                  animate="visible"
                  tab={tab === 1}
                >
                  <p>
                    Whether you are just starting out or are an established
                    player--you have a vision for your brand. I can help you
                    realize that vision.
                  </p>
                  <p>
                    In a collaborative effort, starting from the concepting
                    phase in which we ideate and brainstorm together, all the
                    way to execution and distribution.
                  </p>
                  <p>
                    Together we will create an identity that perfectly suits
                    your brand so it touches a note in your customer, which will
                    lead to more connection and, ultimately, revenue.
                  </p>
                </DesBox>
              )}

              {tab === 2 && (
                <DesBox
                  variants={variants}
                  initial="notvisible"
                  animate="visible"
                  tab={tab === 2}
                >
                  <p>
                    When you launch a website, you want the customer experience
                    to be as frictionless and pleasant as possible. I have
                    contributed my fair share to projects where I was both
                    developer and designer.
                  </p>
                  <p>
                    I know what converts, I know what causes unnecessary
                    friction, but in any case, we'll ask our potential users
                    what they think, so our final design is always
                    user-researched and data-driven, without losing touch
                    creatively.
                  </p>
                </DesBox>
              )}

              {tab === 3 && (
                <DesBox
                  variants={variants}
                  initial="notvisible"
                  animate="visible"
                  tab={tab === 3}
                >
                  <p>
                    What good is a website when nobody is able to find it? As
                    someone with a strong background as an Online Marketeer and
                    Product Owner, I bring some solid experience in
                    discoverability and distribution to the table.
                  </p>
                  <p>
                    I know how to drive quality traffic, all across the funnel
                    so visitors also convert when we've got them to the website.
                    From Paid Search advertising, to Social Media Marketing.
                  </p>
                  <p>
                    I've run succesful campaigns on different platforms, for all
                    of my prior employers, whether it's Facebook & Instagram,
                    LinkedIn or Twitter. I've got you covered.
                  </p>
                </DesBox>
              )}
            </DescriptionGrid>
          </TableGrid>
        </Table>
      </Cont>
    </>
  )
}

const Cont = styled.div`
  h2 {
    color: #252525;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 100%;
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    height: max-content;
  }
`

const Table = styled.div`
  height: 80%;
  width: 70%;
  max-width: 50vw;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow:
      1px 2px 12px rgba(0, 0, 0, 0.07),
      2px 4px 14px rgba(0, 0, 0, 0.07),
      -2px 3px 16px rgba(0, 0, 0, 0.07);
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    max-width: 95vw;
    min-height: max-content;
    max-height: max-content;
  }
`

const TableGrid = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: max-content;
  }
`

const TabGrid = styled.div`
  width: 60%;
  min-height: 100%;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    width: 100%;
    height: 20vh;
    min-height: 200px;
    max-height: 230px;
  }
`

const Tab = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //border: 1px solid black;
  height: 25%;
  width: 100%;
  cursor: pointer;
  padding-left: 40px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #252525;
  transition: 0.3s all ease;
  &:hover {
    //background: #252525;
    background: #e0e0e1;
    color: #484848;
  }
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const DescriptionGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: darkgrey;
  @media only screen and (max-width: 768px) {
    height: max-content;
    min-height: max-content;
  }
  @media only screen and (max-width: 1440px) {
    overflow-y: scroll;
  }
`

const DesBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  font-size: 0.8rem;
  line-height: 1.65rem;
  //border-left: 1px solid rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    justify-content: flex-start;
    padding-bottom: 30px;
    height: max-content;
  }
`

export default ToggleComponent
